<template>
  <div class="app-container">
    <div class="flex-row-center f-between">
      <div class="flex-row-center f-shrink">
        <el-button
          class="gradient-button"
          @click="handleAdd"
        >
          <i class="iconfont icon-tianjia"></i>
          {{ $t('operation.addBtn') }}
        </el-button>
        <el-button
          class="delete-button"
          :disabled="multiple"
          @click="handleDelete"
        >
          <i class="iconfont icon-delete"></i>
          {{$t('operation.delBtn')}}
        </el-button>
      </div>
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <el-date-picker
          v-model="queryParams.daterange"
          class="date-picker"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('time.startDate')"
          :end-placeholder="$t('time.endingDate')"
          @change="handleDateChange"
          clearable
        ></el-date-picker>
        <div
          class="ml16"
          style="width: 400px;"
        >
          <el-input
            class="account-input"
            v-model="queryParams.name"
            :placeholder="$t('operation.enterPlanName')"
            clearable
            @keyup.enter.native="handleQuery"
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <el-table
      v-loading="loading"
      header-row-class-name="custom_table_header"
      height="calc(100vh - 320px)"
      class="mt32"
      :data="programmeList"
      @selection-change="handleSelectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('operation.planName')"
        prop="name"
        min-width="200"
      />
      <el-table-column
        :label="$t('operation.topicCount')"
        align="center"
        prop="videoCount"
        min-width="100"
      />
      <el-table-column
        :label="$t('operation.executionStatus')"
        align="center"
        prop="executeStatus"
        min-width="100"
      >
        <template slot-scope="scope">
          <div :class="'executeStatus-' + scope.row.executeStatus">
            {{ filterState(scope.row.executeStatus) }}
          </div>
          <!-- <el-switch
            v-if="scope.row.executeStatus == 0 || scope.row.executeStatus == 1"
            v-model="scope.row.executeStatus"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
            @change="executeStatusChange(scope.row)"
            :disabled="scope.row.generateStatus != 1"></el-switch>
          <span v-if="scope.row.executeStatus == 2" style="color: gray;">{{$t('operation.completed')}}</span>
          <span v-if="scope.row.executeStatus == 3" style="color: red;">{{$t('operation.failed')}}</span> -->
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.generationStatus')"
        prop="generateStatus"
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <!-- 0生成中1生成成功2失败 -->
          <div
            class="generateStatus"
            :class="'generateStatus-' + scope.row.generateStatus"
          >
            <div
              v-if="scope.row.generateStatus == 0"
              class="flex-column-center"
            >
              <span>{{ $t('status.generating') }}...</span>
              <span class="generateStatus-des">{{ $t('status.generatingTip') }}</span>
            </div>
            <div v-if="scope.row.generateStatus == 1">
              <span>{{ $t('model.generated') }}</span>
            </div>

            <div
              v-if="scope.row.generateStatus == 2"
              class="hidden flex-column-center"
            >
              {{ $t('issueManager.failed') }}
              <!-- <el-tooltip
                v-if="scope.row.errorMsg && scope.row.errorMsg.length > 5"
                :content="scope.row.errorMsg"
                placement="top"
              >
                <span class="single-line-ellipsis pointer font12 error-message">{{ scope.row.errorMsg }}</span>
              </el-tooltip> -->
              <span
                v-if="scope.row.errorMsg"
                class="generateStatus-des"
              >{{ scope.row.errorMsg }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.planTime')"
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <div
            class="flex-center"
            :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'"
          >
            <span>{{ parseTime(scope.row.beginDate, '{y}/{m}/{d}') }}</span>
            -
            <span>{{ parseTime(scope.row.endDate, '{y}/{m}/{d}') }}</span>
          </div>
          <div
            v-show="scope.row.hover"
            class="flex-row-center operate-hover"
          >
            <div
              v-if="scope.row.executeStatus == 0 && scope.row.generateStatus == 1"
              class="flex-row-center list-button"
              @click="executeStatusChange(scope.row, 1)"
            >
              <i class="iconfont icon-guanbi"></i>
              {{ $t('subtitle.open') }}
            </div>
            <div
              v-if="scope.row.executeStatus == 1"
              class="flex-row-center list-button"
              @click="executeStatusChange(scope.row, 0)"
            >
              <i class="iconfont icon-guanbi1"></i>
              {{ $t('subtitle.close') }}
            </div>
            <div
              v-if="scope.row.executeStatus == 0 && scope.row.generateStatus != 0"
              class="flex-row-center list-button"
              @click="handleUpdate(scope.row)"
            >
              <i class="iconfont icon-edit"></i>
              {{ $t('edit') }}
            </div>
            <div
              v-if="scope.row.generateStatus == 1"
              class="flex-row-center list-button"
              @click="handleView(scope.row)"
            >
              <i class="iconfont icon-a-xialajiantoubeifen3"></i>
              {{ $t('view') }}
            </div>
            <div
              v-if="scope.row.executeStatus == 0 && scope.row.generateStatus != 0"
              class="flex-row-center list-button"
              @click="handleDelete(scope.row)"
            >
              <i class="iconfont icon-delete"></i>
              {{ $t('remove') }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('issueManager.social')"
        align="center"
        prop="socialIds"
        min-width="110"
      >
        <template slot-scope="scope">
          <span :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">{{ getAccountNames(scope.row.socialList) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('time.createTime')"
        align="center"
        prop="createTime"
        min-width="150"
      >
        <template slot-scope="scope">
          <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.createTime }}</div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <!-- 添加或修改运营方案对话框 -->
    <el-drawer
      class="publicDrawer"
      :size="700"
      :visible.sync="open"
      :destroy-on-close="true"
      :wrapperClosable="false"
      :with-header="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ title }}</div>
          <i
            class="el-icon-close"
            @click="open = false"
          ></i>
        </div>
      </div>
      <EditStrategy
        ref="editStrategy"
        :accountList="accountList"
        :languageOptions="languageOptions"
        @confirm="confirmEditStrategy"
        @cancel="cancelEditStrategy"
      />
      <!-- @selectionConfirmed="selectionConfirmed" -->
    </el-drawer>
  </div>
</template>

<script>
import { listAccount } from "../../api/account/tiktok";
import { listLanguage } from "../../api/ai/language";
import {
  listProgramme,
  delProgramme,
  executeProgramme,
  abandonProgramme,
} from "../../api/operation/programme";
import EditStrategy from "./editStrategy.vue";
import EventBus from "../../utils/EventBus";

export default {
  // name: "Programme",
  components: {
    EditStrategy,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 运营方案表格数据
      programmeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        daterange: [],
        beginDate: undefined,
        endDate: undefined,
      },
      accountList: [], //社交账号
      languageOptions: [],
      IntervalId: null,
      isPolling: false,
    };
  },
  created() {
    this.getList();
    this.getLanguage();
    this.getListAccount();
  },
  beforeDestroy() {
    clearInterval(this.IntervalId);
  },
  methods: {
    cellMouseLeave(row, column, cell, event) {
      row.hover = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.hover = true;
    },
    filterState(state) {
      let stateText;
      switch (state) {
        case 0:
          stateText = this.$t("operationState.notStarted");
          break;
        case 1:
          stateText = this.$t("operationState.inProgress");
          break;
        case 2:
          stateText = this.$t("operationState.completed");
          break;
        case 3:
          stateText = this.$t("operationState.failed");
          break;
        case 4:
          stateText = this.$t("operationState.starting");
          if (!this.isPolling) {
            this.pollGetList();
            this.isPolling = true;
          }
          break;
      }
      return stateText;
    },
    pollGetList() {
      this.IntervalId = setInterval(() => {
        this.getList(true).finally(() => {
          this.isPolling = false;
        });
      }, 30000);
    },
    executeStatusChange(row, state) {
      // row.executeStatus = state
      EventBus.$emit("switch-executeStatus", { ...row, executeStatus: state });
      if (state == 1) {
        executeProgramme({ id: row.id })
          .then((response) => {
            this.getList();
            this.$message.success(this.$t("operation.executionSuccess"));
            this.pollGetList();
          })
          .catch((error) => {
            row.executeStatus = 0;
          });
        // .finally(() => {
        //   this.getList()
        // })
      } else {
        abandonProgramme({ id: row.id })
          .then((response) => {
            this.getList();
            this.$message.success(this.$t("operation.closeSuccess"));
          })
          .catch((error) => {
            row.executeStatus = 1;
            console.log(row);
          });
      }
    },
    // 日期选择
    handleDateChange() {
      if (this.queryParams.daterange && this.queryParams.daterange.length > 0) {
        this.queryParams.beginDate = this.queryParams.daterange[0];
        this.queryParams.endDate = this.queryParams.daterange[1];
      } else {
        this.queryParams.beginDate = "";
        this.queryParams.endDate = "";
      }
      this.getList();
    },
    // 获取语言列表
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
    // 获取社交账号列表
    getListAccount() {
      listAccount().then((response) => {
        this.accountList = response.rows;
      });
    },
    getAccountNames(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.displayName).join(",");
      } else {
        return "--";
      }
    },
    getAccounts(ids) {
      if (!ids) {
        return "--";
      }
      // 将 ids 转换为数组
      const idArray = ids.split(",").map((id) => id.trim());
      // 查找每个 id 对应的账号信息
      const accounts = idArray.map((id) => {
        const account = this.accountList.find((account) => account.id == id);
        return account ? account.username : "--";
      });
      // 返回找到的账号信息
      return accounts.join(",");
    },
    /** 查询运营方案列表 */
    getList(state) {
      if (!state) {
        this.loading = true;
      }
      listProgramme(this.queryParams)
        .then((response) => {
          this.programmeList = response.rows;
          this.programmeList.forEach((item) => {
            this.$set(item, "hover", false);
          });
          this.total = response.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        daterange: [],
        beginDate: undefined,
        endDate: undefined,
      };
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open = true;
      this.title = this.$t("operation.addPlan");
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const id = row.id || this.ids;
      this.title = this.$t("operation.editPlan");
      this.open = true;
      this.$nextTick().then((rs) => {
        this.$refs.editStrategy.init(id);
      });
    },
    confirmEditStrategy() {
      this.open = false;
      this.getList();
    },
    cancelEditStrategy() {
      this.open = false;
    },
    //查看
    handleView(row) {
      this.$router.push({
        path: `/programmeDetail/${row.id}`,
        query: { executeStatus: row.executeStatus },
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal
        .confirm(this.$t("operation.confirmDeletePlan"))
        .then(() => {
          this.loading = true;
          return delProgramme(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "admin/programme/export",
        {
          ...this.queryParams,
        },
        `programme_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.date-picker {
  width: 240px;
  height: 40px;
  border: none;
  background: #f4f4f5;
  border-radius: 8px;
}
::v-deep .el-range-editor--medium .el-range-input {
  background: transparent;
}
::v-deep .el-range-editor--medium .el-range-separator {
  line-height: 40px;
}
.executeStatus-0 {
  color: rgba(0, 0, 0, 0.9);
}
.executeStatus-1 {
  color: #ff7800;
}
.executeStatus-2 {
  color: #016fee;
}
.executeStatus-3 {
  color: #ff0000;
}
.executeStatus-4 {
  color: #00cd17;
}

.generateStatus {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
}
.generateStatus-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.generateStatus-0 {
  color: #ed7b2f;
}
.generateStatus-1 {
  color: #0156ff;
}
.generateStatus-2 {
  color: #e34d59;
}
::v-deep .el-drawer__header {
  margin-bottom: 0;
}
.el-drawer__close-btn {
  border: none;
}
</style>
