<!-- 素材item -->
<template>
  <div
    class="flex-column model-item"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
  >
    <div
      class="flex-row-center"
      style="margin-top: 24px;"
    >
      <div class="model-image flex-center f-shrink">
        <i
          class="iconfont icon-moxingxunlianbeifen"
          style="font-size: 26px;color:white;"
        ></i>
      </div>
      <span class="model-name ellipsis-option">{{ item.modelName }}</span>
      <div
        v-if="item.status==1"
        class="flex-row-center model-status"
        style="color: #0156FF;"
      >
        <i class="iconfont icon-a-dacha3"></i>
        {{ $t('model.generated') }}
      </div>
      <div
        v-else-if="item.status==0"
        class="flex-row-center model-status"
        style="color: #00B264;"
      >
        <i class="iconfont icon-gongzuotai2"></i>
        {{ $t('model.trainingInProgress') }}
      </div>
      <el-tooltip :content="item.errorMsg">
        <div
          v-if="item.status==2"
          class="flex-row-center model-status"
          style="color: #E34D59;"
        >
          <i class="iconfont icon-a-dacha2"></i>
          {{ $t('model.trainingFailed') }}
        </div>
      </el-tooltip>
    </div>
    <span class="line-2 model-description">{{ item.description }}</span>
    <div
      class="model-bottom flex-row-center f-end"
      v-if="!item.hover"
    >
      <!-- <div>模型已引用 {{ item.times }} 次</div> -->
      <div>{{ item.createTime }}</div>
    </div>
    <div
      class="model-buttons flex-center"
      v-if="item.hover&&item.status!=0"
    >
      <el-tooltip :content="$t('view')">
        <i
          class="iconfont icon-a-xialajiantoubeifen3 color-blue"
          @click.stop="$emit('view')"
        ></i>
      </el-tooltip>
      <el-tooltip :content="$t('edit')">
        <i
          class="iconfont icon-edit color-blue"
          @click.stop="$emit('edit')"
        ></i>
      </el-tooltip>
      <!-- <el-tooltip :content="$t('copyLink')">
        <i
          class="iconfont icon-lianjiebeidianjiliang color-blue"
          @click.stop="copyLink"
        ></i>
      </el-tooltip> -->
      <el-tooltip :content="$t('remove')">
        <i
          class="iconfont icon-delete color-blue"
          @click.stop="delModel"
        ></i>
      </el-tooltip>
    </div>
    <div
      class="model-buttons flex-center"
      v-if="item.hover&&item.status==0"
    >
      {{ $t('model.trainingLocked') }}
    </div>
  </div>
</template>

<script>
import { delModel } from "../../api/model/model";

export default {
  name: "ModelItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    mouseleaveItem() {
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      this.$set(this.item, "hover", true);
    },
    delModel() {
      this.$modal
        .confirm(this.$t('model.confirmDeleteModel'))
        .then(() => {
          return delModel(this.item.id);
        })
        .then(() => {
          this.$modal.msgSuccess(this.$t('tip.remove'));
          this.$emit("del-success", this.item);
        });
    },
  },
};
</script>

<style scoped>
.model-item {
  overflow: hidden;
  cursor: pointer;
}
.model-image {
  width: 40px;
  height: 40px;
  background: #6a5ae0;
  border-radius: 12px;
  margin-right: 16px;
  margin-left: 24px;
}
.model-name {
  height: 24px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
  flex-grow: 1;
}
.model-status {
  flex-shrink: 0;
  margin: 0 24px 0 10px;
}
.model-description {
  height: 40px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 16px 24px 0;
}
.model-bottom {
  height: 37px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 21px 24px 0;
}
.model-buttons {
  height: 37px;
  background: #f4f5ff;
  border-radius: 0px 0px 12px 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  color: #0156ff;
}
.strikethrough {
  text-decoration: line-through; /* 添加划线效果 */
  color: #999; /* 可选：调整颜色以区分原价 */
}

.icon-a-dacha3,
.icon-gongzuotai2,
.icon-a-dacha2 {
  margin-right: 2px;
}
</style>
