<template>
  <div class="app-container">
    <div class="cut-scroll">
      <el-form
        v-loading="loading"
        ref="form"
        label-position="top"
        class="form-top"
        :model="form"
        :rules="rules"
      >
        <div class="model-title">{{ $t('model.basicInfo') }}</div>
        <el-form-item
          :label="$t('model.modelName')"
          prop="modelName"
        >
          <el-input
            v-model="form.modelName"
            maxlength="50"
            :placeholder="$t('tip.input')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('model.modelDescription')"
          prop="description"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            maxlength="200"
            :placeholder="$t('tip.input')"
            :rows="3"
          />
        </el-form-item>
        <div class="model-title">{{ $t('model.fileModel') }}</div>
        <el-form-item
          :label="$t('model.trainingModule')"
          prop="modelType"
        >
          <el-radio-group v-model="form.modelType">
            <el-radio
              v-for="dict in dict.type.training_module"
              :key="dict.value"
              :label="dict.value"
            >
              {{ dict.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="model-tip flex-row-center">
          <i class="el-icon-warning"></i>
          {{ $t('model.trainingAdvice') }}
        </div>
        <el-form-item prop="files">
          <videoUpload
            v-model="form.files"
            :buttonText="$t('model.upload')"
            :limit="10"
            :fileSize="50"
            :fileType="fileType"
          />
        </el-form-item>

        <div class="model-title">{{ $t('model.qnaModel') }}</div>
        <div
          v-for="(item, index) in form.questions"
          :key="index"
          class="question-item flex-row-center"
        >
          <el-input
            :placeholder="$t('tip.input')"
            v-model="item.problem"
            maxlength="200"
          >
            <template slot="prepend">{{ $t('model.question') }}</template>
          </el-input>
          <el-input
            :placeholder="$t('tip.input')"
            v-model="item.answer"
            style="margin-left: 15px;"
            maxlength="200"
          >
            <template slot="prepend">{{ $t('model.answer') }}</template>
          </el-input>
          <i
            class="iconfont icon-delete"
            @click="deleteQuestion(index)"
          ></i>
        </div>
        <el-button
          class="normal-button"
          @click="addQestion"
        ><i class="el-icon-plus"></i>{{ $t('model.addQna') }}</el-button>

        <div
          class="model-title mt16"
          v-if="(form.modelFiles && form.modelFiles.length > 0)||(form.modelDocumentSegments && form.modelDocumentSegments.length > 0)"
        >{{ $t('model.trainingResult') }}</div>
        <div v-if="form.modelFiles && form.modelFiles.length > 0">{{ $t('model.fileModel') }}</div>
        <div
          v-for="(item, index) in form.modelFiles"
          :key="item.id"
          class="modelFile-item flex-row-center f-between"
        >
          <span>{{ item.fileName }}</span>
          <span>{{ item.fileSize }}</span>
          <span>{{ item.modelType }}</span>
          <span class="success-blue"><i class="el-icon-circle-check"></i>{{ $t('model.trainingSuccess') }}</span>
          <span>{{ item.createTime }}</span>
          <span>
            <el-tooltip :content="$t('file.download')">
              <i
                v-if="item.fileUrl"
                class="iconfont icon-a-dagoubeifen21"
                @click="downloadFile(item.fileUrl)"
              ></i>
            </el-tooltip>
            <el-tooltip :content="$t('remove')">
              <i
                class="iconfont icon-delete"
                @click="deleteFile(item,index)"
              ></i>
            </el-tooltip>
          </span>
        </div>
        <div v-if="form.modelDocumentSegments && form.modelDocumentSegments.length > 0">{{ $t('model.qnaModel') }}</div>
        <div v-if="isShowQuestion">
          <div class="question-item flex-row-center">
            <el-input
              :placeholder="$t('tip.input')"
              v-model="currentQuestion.problem"
              maxlength="200"
            >
              <template slot="prepend">{{ $t('model.question') }}</template>
            </el-input>
            <el-input
              :placeholder="$t('tip.input')"
              v-model="currentQuestion.answer"
              style="margin-left: 15px;"
              maxlength="200"
            >
              <template slot="prepend">{{ $t('model.answer') }}</template>
            </el-input>
          </div>
          <div class="flex-center mt10 mb10">
            <el-button
              class="button-white"
              @click="cancelQestion"
            >{{ $t('cancel') }}</el-button>
            <el-button
              :loading="quesLoading"
              class="gradient-button"
              @click="confirmQestion"
            >{{ $t('sure') }}</el-button>
          </div>
        </div>
      </el-form>
      <div
        v-for="(item, index) in form.modelDocumentSegments"
        :key="item.id"
        class="modelFile-item flex-row-center f-between"
      >
        <span>{{ item.problem }}</span>
        <span>{{ item.modelType }}</span>
        <span class="success-blue"><i class="el-icon-circle-check"></i>{{ $t('model.trainingSuccess') }}</span>
        <span>{{ item.createTime }}</span>
        <span>
          <el-tooltip :content="$t('edit')">
            <i
              class="iconfont icon-edit"
              @click="editDocument(item)"
            ></i>
          </el-tooltip>
          <el-tooltip :content="$t('remove')">
            <i
              class="iconfont icon-delete"
              @click="deleteDocument(item,index)"
            ></i>
          </el-tooltip>
        </span>
      </div>
    </div>
    <div class="flex-center mt10">
      <el-button
        class="button-white"
        @click="cancel"
      >{{ $t('cancel') }}</el-button>
      <el-button
        :loading="buttonLoading"
        class="gradient-button"
        @click="submitForm"
      >{{ $t('model.startTraining') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  delModelDocumentSegments,
  updateModelDocumentSegments,
} from "../../api/model/modelDocumentSegments";
import { delModelFile } from "../../api/model/modelFile";
import VideoUpload from "../../components/VideoUpload/index.vue";
import { getModel, addModel, updateModel } from "@/api/model/model";
export default {
  name: "AddModel",
  dicts: ["training_module"],
  components: {
    VideoUpload,
  },
  data() {
    return {
      fileType: ["txt", "doc", "docx", "pdf"],
      // 表单参数
      form: {
        modelType: this.$t("model.generalInfo"),
        questions: [],
      },
      // 表单校验
      rules: {
        modelName: [
          {
            required: true,
            message: this.$t("model.enterModelName"),
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: this.$t("model.enterModelDescription"),
            trigger: "blur",
          },
        ],
        modelType: [
          {
            required: true,
            message: this.$t("model.selectTrainingModule"),
            trigger: "blur",
          },
        ],
      },
      modelId: null,
      loading: false,
      buttonLoading: false,
      currentQuestion: null, //当前编辑的问答项——已经上传过的问答，重新编辑
      isShowQuestion: false, //是否显示问答输入框
      quesLoading: false, //问答加载
    };
  },
  created() {
    this.modelId = this.$route.params.id || "";
    if (this.modelId) {
      this.getDetail();
    }
  },
  methods: {
    // 添加问答
    addQestion() {
      this.form.questions.push({ problem: "", answer: "" });
      // this.$forceUpdate();
      // this.$set(this.form.questions, this.form.questions.length - 1, {
      //   problem: "",
      //   answer: "",
      // });
    },
    deleteQuestion(index) {
      this.form.questions.splice(index, 1);
      // this.$forceUpdate();
    },
    // 删除已经训练过的文件
    deleteFile(item, index) {
      this.$confirm(this.$t("model.confirmDeleteFile"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        delModelFile(item.id).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.form.modelFiles.splice(index, 1);
          this.$forceUpdate();
        });
      });
    },
    // 编辑问答
    editDocument(item) {
      this.isShowQuestion = true;
      this.currentQuestion = { ...item };
    },
    // 删除问答
    deleteDocument(item, index) {
      this.$confirm(this.$t("model.confirmDeleteQna"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        delModelDocumentSegments(item.id).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.form.modelDocumentSegments.splice(index, 1);
          this.$forceUpdate();
        });
      });
    },
    // 取消问答
    cancelQestion() {
      this.isShowQuestion = false;
    },
    // 确认问答
    confirmQestion() {
      if (!this.currentQuestion.problem || !this.currentQuestion.answer) {
        this.$modal.msgError(this.$t("model.completeQna"));
        return;
      }
      this.quesLoading = true;
      updateModelDocumentSegments(this.currentQuestion)
        .then((response) => {
          this.$modal.msgSuccess(this.$t("tip.update"));
          this.isShowQuestion = false;
          this.currentQuestion = {};
          this.getDetail();
        })
        .finally(() => {
          this.quesLoading = false;
        });
    },
    getDetail() {
      this.loading = true;
      getModel(this.modelId)
        .then((response) => {
          this.form = {
            ...response.data,
            questions: [],
            modelType: this.$t("model.generalInfo"),
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkQuestionsComplete() {
      // 遍历 questions 数组，检查每个问题的 problem 和 answer 是否都存在
      const isComplete = this.form.questions.every(
        (item) => item.problem && item.answer
      );
      if (!isComplete) {
        this.$modal.msgError(this.$t("model.completeQna")); // 显示提示信息
      }
      return isComplete;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.checkQuestionsComplete()) {
            return;
          }
          if (
            (!this.form.questions || this.form.questions.length == 0) &&
            (!this.form.files || this.form.files.length == 0)
          ) {
            this.$modal.msgError(this.$t("model.uploadAtLeastOne")); // 显示提示信息
            return;
          }
          if (this.form.questions)
            this.form.questions.forEach((item) => {
              item.modelType = this.form.modelType;
            });
          if (this.form.files)
            this.form.files.forEach((item) => {
              item.fileName = item.name;
              item.modelType = this.form.modelType;
            });
          this.buttonLoading = true;
          if (this.modelId) {
            updateModel(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$tab.closeOpenPage({ path: "/modelTraining" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            addModel(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$tab.closeOpenPage({ path: "/modelTraining" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$tab.closeOpenPage({ path: "/modelTraining" });
    },
  },
};
</script>

<style scoped  lang="scss">
.form-top ::v-deep .el-form-item__label {
  padding: 0;
}
.form-top .el-form-item {
  margin-bottom: 15px;
}
.form-top .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
.model-title {
  background: rgba(236, 242, 254, 0.3);
  border-radius: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0052d9;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
  padding: 5px 16px;
}
.model-tip {
  background: #e9f1fd;
  border-radius: 12px;
  padding: 14px 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 16px;

  .el-icon-warning {
    color: #0052d9;
    font-size: 18px;
    margin-right: 8px;
  }
}
.question-item {
  background: rgba(56, 125, 238, 0.02);
  border-radius: 12px;
  border: 1px solid #dddddd;
  padding: 24px 16px;
  margin-bottom: 16px;

  .icon-delete {
    color: #fe4243;
    font-size: 14px;
    margin-left: 16px;
    cursor: pointer;
  }
}

.modelFile-item {
  background: #f6f8fc;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  padding: 10px 30px;
  margin-bottom: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  font-style: normal;
  .success-blue {
    color: #0156ff;
  }
  .el-icon-circle-check {
    font-size: 16px;
    margin-right: 6px;
  }

  .iconfont {
    color: #181819;
    font-size: 16px;
    cursor: pointer;
  }
  .icon-a-dagoubeifen21,
  .icon-edit {
    margin-right: 12px;
  }
}

.cut-scroll {
  overflow-y: auto;
  height: calc(100vh - 200px);
  scroll-padding-bottom: 20px;
}
</style>
