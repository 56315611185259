<template>
  <div class="mt-manager">
    <div
      class="flex-row-center f-between mb16"
      style="height: 48px;"
    >
      <div class="mt-leftActiveTab">
        <div
          v-for="item in tabList"
          :key="item.label"
          :class="['tabItem', item.isActive && 'activeTab']"
          @click="clickSearchTable(item)"
        >
          <i :class="['iconfont', item.iconFont]"></i>
          {{ item.label }}
        </div>
      </div>
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.modelName"
            :placeholder="$t('model.enterModelName')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <div
      class="flex-row f-wrap w-100"
      v-loading="loading"
    >
      <div
        class="mt-model flex-column-center f-center"
        @click="handleAdd"
      >
        <div class="mt-add flex-center">
          +
        </div>
        <div class="mt-add-text">{{ $t('model.addModel') }}</div>
      </div>
      <div
        v-for=" (item,index) in modelList"
        :key="index"
        class="mt-model-item"
      >
        <ModelItem
          :item="item"
          @del-success="getList"
          @edit="handleUpdate(item)"
          @view="handleView(item)"
        />
      </div>
    </div>

    <!-- 查看、编辑抽屉 -->
    <el-drawer
      class="publicDrawer"
      size="50%"
      :visible.sync="modelDrawer"
      :with-header="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('model.modelInfo') }}</div>
          <i
            class="el-icon-close"
            @click="modelDrawer = false"
          ></i>
        </div>
      </div>
      <Detail
        ref="modelDetail"
        @cancel="modelDrawer = false"
        style="padding: 20px;"
      ></Detail>
    </el-drawer>
  </div>
</template>

<script>
import {
  listModel,
  getModel,
  delModel,
  addModel,
  updateModel,
} from "@/api/model/model";
import ModelItem from "./modelItem.vue";
import Detail from "./detail.vue";

export default {
  name: "ModelApp",
  components: {
    ModelItem,
    Detail,
  },
  data() {
    return {
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      // 模型应用表格数据
      modelList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        modelName: undefined,
      },
      tabList: [
        {
          label: this.$t("all"),
          iconFont: "icon-quanbu",
          isActive: true,
          statusList: [],
        },
        {
          label: this.$t("model.generate"),
          iconFont: "icon-shuju",
          isActive: false,
          statusList: [1, 2, 3],
        },
        {
          label: this.$t("issueManager.statusDraft"),
          iconFont: "icon-a-dagoubeifen23",
          isActive: false,
          statusList: [0],
        },
      ],
      modelDrawer: false,
      timer: null, //定时器——生成中的状态更新
    };
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    this.clearTimer();
  },
  methods: {
    /** 查询模型应用列表 */
    getList() {
      this.loading = true;
      listModel(this.queryParams)
        .then((response) => {
          this.modelList = response.rows;
          // 检查是否存在 训练中 的项
          if (this.modelList.some((item) => item.status == 0)) {
            // 如果定时器不存在，启动定时器
            if (!this.timer) {
              this.timer = setInterval(() => {
                this.getList();
              }, 30000); // 每30秒调用一次
            }
          } else {
            this.clearTimer();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    clickSearchTable(item) {
      this.tabList.forEach((tabItem) => {
        if (item.label === tabItem.label) {
          this.queryParams.statusList = item.statusList;
          tabItem.isActive = true;
          this.handleQuery();
        } else {
          tabItem.isActive = false;
        }
      });
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({ path: "/addModel" });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push({ path: `/addModel/${row.id}` });
    },
    /** 查看按钮操作 */
    handleView(row) {
      this.modelDrawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.modelDetail.init(row.id, false);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-manager {
  margin: 16px 24px;
}

.mt-leftActiveTab {
  display: flex;
  .tabItem {
    height: 22px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
    margin-right: 24px;
  }
  .activeTab {
    background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);
    background-clip: text; /* 无前缀标准写法 */
    -webkit-background-clip: text; /* 兼容 WebKit 浏览器 */
    -webkit-text-fill-color: transparent;
  }
}
.mt-model {
  width: calc(25% - 24px);
  height: 180px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px dashed rgba(179, 179, 179, 0.38);
  margin: 0 24px 24px 0;
  cursor: pointer;

  .mt-add {
    width: 60px;
    height: 60px;
    font-size: 28px;
    color: #0156ff;
    border-radius: 19px;
    background: #e5eeff;
  }
  .mt-add-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #8f92a1;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-top: 16px;
  }
}
.mt-model-item {
  width: calc(25% - 24px);
  height: 180px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid rgba(179, 179, 179, 0.38);
  margin: 0 24px 24px 0;
  background-image: url("../../assets/images/model_bg.png");
  background-size: 100% 100%; /* 背景图片宽高拉伸充满整个屏幕 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}
</style>
