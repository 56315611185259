<template>
  <div class="app-container">
    <div class="flex-row-center f-between">
      <div
        class="flex-row-center f-shrink"
        v-if="executeStatus == 0 ? true : false"
      >
        <el-button
          class="gradient-button"
          @click="handleAdd"
        >
          <i class="iconfont icon-tianjia"></i>
          {{ $t('operation.addTopic') }}
        </el-button>
        <el-button
          class="delete-button"
          :disabled="multiple"
          @click="handleDelete"
        >
          <i class="iconfont icon-delete"></i>
          {{$t('operation.delBtn')}}
        </el-button>
      </div>
      <div v-else></div>
      <div class="flex-row f-end">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
      </div>
    </div>
    <el-table
      v-loading="loading"
      header-row-class-name="custom_table_header"
      height="calc(100vh - 220px)"
      class="mt32"
      :data="programmeItemsList"
      @selection-change="handleSelectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('operation.topic')"
        align="center"
        prop="videoTheme"
      />
      <!-- <el-table-column label="时区" align="center" prop="languageId" >
        <template slot-scope="scope">
          {{ formatLanguage(scope.row.languageId) }}
        </template>
      </el-table-column> -->
      <el-table-column
        :label="$t('operation.video')"
        align="center"
        prop="videoUrl"
      >
        <template slot-scope="scope">
          <div class="flex-center">
            <div
              v-if="scope.row.videoGenerateStatus == 0"
              class="video-cover video-bg flex-center"
            >
              <i class="iconfont icon-shipinshengchengzhong"></i>
            </div>
            <div
              v-else-if="scope.row.videoGenerateStatus == 2"
              class="video-cover video-bg flex-center"
            >
              <i class="iconfont icon-shipinshengchengshibai"></i>
            </div>
            <div
              v-else
              class="cellVideo"
            >
              <video
                class="video-cover"
                :src="scope.row.videoUrl"
              ></video>
              <video-button
                :url="scope.row.videoUrl"
                :tip="$t('issueManager.playVideo')"
              >
                <template slot="customButton">
                  <div class="cricle">
                    <i class="el-icon-caret-right"></i>
                  </div>
                </template>
              </video-button>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.generationStatus')"
        align="center"
        prop="videoGenerateStatus"
        width="180"
      >
        <template slot-scope="scope">
          <div
            class="videoGenerateStatus"
            :class="'videoGenerateStatus-' + scope.row.videoGenerateStatus"
            :style="scope.row.hover && executeStatus == 0 ? 'opacity: 0' : 'opacity: 1'"
          >
            <span v-if="scope.row.videoGenerateStatus == 1">{{ $t('model.generated') }}</span>
            <div
              v-else-if="scope.row.videoGenerateStatus == 2"
              class="flex-column-center"
            >
              {{ $t('issueManager.failed') }}
              <span
                v-if="scope.row.errorMsg"
                class="generateStatus-des"
              >{{ scope.row.errorMsg }}</span>
            </div>
            <div
              v-else
              class="flex-column-center"
            >
              <span>{{ $t('status.generating') }}...</span>
              <span class="videoGenerateStatus-des">{{ $t('status.generatingTip') }}</span>
            </div>
          </div>
          <div
            v-show="scope.row.hover"
            class="flex-row-center operate-hover"
          >
            <div
              v-if="executeStatus == 0 ? scope.row.publishStatus == 0 ? true : false : false"
              class="flex-row-center list-button"
              @click="handleUpdate(scope.row)"
            >
              <i class="iconfont icon-edit"></i>
              {{ $t('edit') }}
            </div>
            <div
              v-if="executeStatus == 0 ? true : false"
              class="flex-row-center list-button"
              @click="handleDelete(scope.row)"
            >
              <i class="iconfont icon-delete"></i>
              {{ $t('remove') }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.state')"
        align="center"
        prop="publishStatus"
        width="180"
      >
        <template slot-scope="scope">
          <div
            class="publish-status"
            :class="'publish-status-' + scope.row.publishStatus"
            :style="scope.row.hover && executeStatus == 0 ? 'opacity: 0' : 'opacity: 1'"
          >
            <div
              v-if="scope.row.publishStatus == 0"
              class="flex-center"
            > <i class="iconfont icon-dacha3"></i>
              {{ $t('issueManager.unpublished') }}
            </div>
            <div
              v-if="scope.row.publishStatus == 1"
              class="flex-center"
            >
              <i class="iconfont icon-a-dacha3"></i>
              {{ $t('issueManager.published') }}
            </div>
            <div
              v-if="scope.row.publishStatus == 2"
              class="hidden flex-column"
            >
              <div class="flex-center">
                <i class="iconfont icon-a-dacha2"></i>
                {{ $t('issueManager.failed') }}
              </div>
              <span
                v-if="scope.row.errorMsg"
                class="publish-status-des"
              >{{ scope.row.errorMsg }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.releaseTime')"
        align="center"
        prop="publishTime"
        width="180"
      >
        <template slot-scope="scope">
          <span :style="scope.row.hover && executeStatus == 0 ? 'opacity: 0' : 'opacity: 1'">{{ parseTime(scope.row.publishTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->

    <!-- 添加或修改运营方案明细对话框 -->
    <el-drawer
      class="publicDrawer"
      :size="700"
      :visible.sync="open"
      :destroy-on-close="true"
      :wrapperClosable="false"
      :with-header="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ title }}</div>
          <i
            class="el-icon-close"
            @click="open = false"
          ></i>
        </div>
      </div>
      <EditStrategyItem
        ref="editStrategy"
        :programmeId="queryParams.programmeId"
        :languageOptions="languageOptions"
        @confirm="confirmEditStrategy"
        @cancel="cancelEditStrategy"
      />
    </el-drawer>
  </div>
</template>

<script>
import { listLanguage } from "../../api/ai/language";
import { getProgramme } from "../../api/operation/programme";
import {
  listProgrammeItems,
  delProgrammeItems,
} from "../../api/operation/programmeItems";
import EditStrategyItem from "./editStrategyItem.vue";
import VideoButton from "../../components/VideoButton/VideoButton.vue";
import EventBus from "../../utils/EventBus";

export default {
  // name: "ProgrammeDetail",
  components: {
    EditStrategyItem,
    VideoButton,
  },
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      // total: 0,
      // 运营方案明细表格数据
      programmeItemsList: [],
      programmeDetail: {}, //方案详情
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      executeStatus: this.$route.query.executeStatus,
      // 查询参数
      queryParams: {
        programmeId: "",
        pageNum: 1,
        pageSize: 10,
      },
      languageOptions: [],
    };
  },
  created() {
    this.queryParams.programmeId = this.$route.params.id || "";
    if (this.queryParams.programmeId) {
      this.getList();
      this.getDetail();
    }
    this.getLanguage();
  },
  mounted() {
    EventBus.$on("switch-executeStatus", (row) => {
      if (row.id === this.queryParams.programmeId) {
        this.executeStatus = row.executeStatus;
      }
    });
    EventBus.$on("jump-executeStatus", (state) => {
      this.executeStatus = state;
    });
  },
  methods: {
    cellMouseLeave(row, column, cell, event) {
      row.hover = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.hover = true;
    },
    // 获取语言列表
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
    /** 查询运营方案明细列表 */
    getList() {
      this.loading = true;
      listProgrammeItems(this.queryParams)
        .then((response) => {
          this.programmeItemsList = response.data;
          this.programmeItemsList.forEach((item) => {
            this.$set(item, "hover", false);
          });
          // this.total = response.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDetail() {
      getProgramme(this.queryParams.programmeId).then((response) => {
        this.programmeDetail = response.data;
        console.log("this.programmeDetail", this.programmeDetail);
      });
    },
    formatLanguage(languageId) {
      const language = this.languageOptions.find(
        (item) => item.id === languageId
      );
      if (language) {
        const parts = language.name.split("(");
        if (parts.length === 2) {
          const country = parts[1].replace(")", "").trim();
          const languageName = parts[0].trim();
          return `${country} ${languageName}`;
        }
        return language.name;
      }
      return "--";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open = true;
      this.title = this.$t("operation.createVideoTopic");
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const id = row.id || this.ids;
      this.title = this.$t("operation.editVideoTopic");
      this.open = true;
      this.$nextTick().then((rs) => {
        this.$refs.editStrategy.init(id);
      });
    },
    confirmEditStrategy() {
      this.open = false;
      this.getList();
    },
    cancelEditStrategy() {
      this.open = false;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal
        .confirm(this.$t("operation.confirmDelete"))
        .then(() => {
          this.loading = true;
          return delProgrammeItems(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "admin/programmeItems/export",
        {
          ...this.queryParams,
        },
        `programmeItems_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>
<style scoped lang="scss">
.cellVideo {
  width: 102px;
  height: 56px;
  position: relative;
  overflow: hidden;
  .cricle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    .el-icon-caret-right {
      color: #fff;
      font-size: 14px;
    }
  }
}
.video-cover {
  width: 102px;
  height: 56px;
  border-radius: 12px;
  object-fit: cover; /* 保持比例，裁剪多余部分 */
}
.videoGenerateStatus {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
}
.videoGenerateStatus-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.videoGenerateStatus-0 {
  color: #ed7b2f;
}
.videoGenerateStatus-1 {
  color: #0156ff;
}
.videoGenerateStatus-2 {
  color: #e34d59;
}

.publish-status {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  font-style: normal;
  i {
    margin-right: 8px;
  }
}
.publish-status-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.publish-status-0 {
  color: rgba(0, 0, 0, 0.4);
}
.publish-status-1 {
  color: #00a870;
}
.publish-status-2 {
  color: #e34d59;
}
::v-deep .el-drawer__header {
  margin-bottom: 0;
}
</style>
