<!-- 新建or编辑方案 -->
<template>
  <div class="app-container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item
        :label="$t('operation.planName')"
        prop="name"
      >
        <el-input
          v-model="form.name"
          maxlength="50"
          :placeholder="$t('operation.planName')"
        />
      </el-form-item>
      <el-form-item
        :label="$t('operation.timePeriod')"
        prop="daterange"
      >
        <el-date-picker
          :disabled="disabled"
          v-model="form.daterange"
          style="width: 500px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('time.startDate')"
          :end-placeholder="$t('time.endingDate')"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="模型" prop="modolId">
        <el-select
          v-model="form.modolId"
          filterable
          :placeholder="$t('label.tipSelect')"
          ref="searchSelect" @input="handleInput" style="width:100%;"
        >
          <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
        </el-select>
      </el-form-item> -->
      <el-form-item
        :label="$t('operation.videoTopicCount')"
        prop="videoCount"
      >
        <div style="color: gray;font-size: 12px;">
          {{$t('operation.videoTopicRangea')}}
        </div>
        <el-input-number
          :disabled="disabled"
          v-model="form.videoCount"
          style="width: 500px;"
          :min="1"
          :max="100"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="$t('operation.releaseMethod')"
        prop="publishType"
      >
        <el-radio-group
          v-model="form.publishType"
          :disabled="disabled"
        >
          <el-radio label="time">{{$t('operation.distributeEvenly')}}</el-radio>
          <el-radio label="ai">{{$t('operation.aiAssign')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('operation.userProfile')"
        prop="userPortrait"
      >
        <el-input
          :disabled="disabled"
          type="textarea"
          v-model="form.userPortrait"
          maxlength="200"
          show-word-limit
          :placeholder="$t('operation.enterUserProfile')"
          :autosize="{ minRows: 6, maxRows: 8}"
        />
      </el-form-item>
      <el-form-item
        :label="$t('operation.planLanguage')"
        prop="languageValue"
      >
        <el-select
          v-model="form.languageValue"
          filterable
          class="w-100"
          :placeholder="$t('operation.selectPlanLanguage')"
          :disabled="disabled"
          @change="languageChange"
        >
          <el-option
            v-for="dict in dict.type.language"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('operation.socialAccount')"
        prop="socialIds"
      >
        <!-- 注意区分这里的 socialIds 和socialId -->
        <div style="color: gray;font-size: 12px;">{{$t('operation.accountSupport')}}</div>
        <el-select
          v-model="form.socialId"
          filterable
          multiple
          :placeholder="$t('operation.selectSocialAccount')"
          @change="socialIdChange"
          class="w-100"
        >
          <el-option
            v-for="account in accountList"
            :key="account.id"
            :label="account.displayName"
            :value="String(account.id)"
          >
            <span style="float: left">{{ account.displayName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ formatLanguage(account.languageId) }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="f-shrik flex-center mt32">
      <el-button
        class="cancelBtn"
        @click="$emit('cancel')"
      >{{ $t('cancel') }}</el-button>
      <el-button
        :loading="buttonLoading"
        class="submitBtn"
        @click="submitForm"
      >{{ $t('confirm') }}</el-button>
    </div>
  </div>
</template>
<script>
import {
  addProgramme,
  getProgramme,
  updateProgramme,
} from "../../api/operation/programme";
export default {
  name: "editStrategy",
  dicts: ["language"],
  props: {
    accountList: {
      type: Array,
      default: () => [],
    },
    languageOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      buttonLoading: false, // 按钮loading
      pickerOptions: {
        // 禁用掉今天之前的日期不让选择
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      form: {
        id: undefined,
        name: undefined,
        daterange: [],
        beginDate: undefined,
        endDate: undefined,
        videoCount: 20,
        // modolId: undefined,
        publishType: "time",
        // userPortrait: this.$t('operation.targetDetails'),
        userPortrait: undefined,
        languageId: undefined,
        languageValue: undefined,
        socialId: [],
        socialIds: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("operation.nameRequired"),
            trigger: ["blur", "change"],
          },
        ],
        daterange: [
          {
            required: true,
            message: this.$t("operation.selectTimePeriod"),
            trigger: ["blur", "change"],
          },
        ],
        // modolId: [
        //   { required: true, message: "模型id不能为空", trigger: ['blur', 'change'] }
        // ],
        videoCount: [
          {
            required: true,
            message: this.$t("operation.videoTopicRequired"),
            trigger: ["blur", "change"],
          },
        ],
        publishType: [
          {
            required: true,
            message: this.$t("operation.selectReleaseMethod"),
            trigger: ["blur", "change"],
          },
        ],
        userPortrait: [
          {
            required: true,
            message: this.$t("operation.userProfileRequired"),
            trigger: ["blur", "change"],
          },
        ],
        languageValue: [
          {
            required: true,
            message: this.$t("operation.selectPlanLanguage"),
            trigger: ["blur", "change"],
          },
        ],
        socialIds: [
          {
            required: true,
            message: this.$t("operation.selectSocialAccount"),
            trigger: ["blur", "change"],
          },
        ],
      },
      disabled: false, //是否禁用编辑项
    };
  },
  created() {},
  methods: {
    init(id) {
      this.disabled = true;
      getProgramme(id).then((response) => {
        this.loading = false;
        this.form = response.data;
        if (this.form.beginDate && this.form.endDate) {
          this.form.daterange = [this.form.beginDate, this.form.endDate];
        }
        if (this.form.socialIds) {
          this.form.socialId = this.form.socialIds.split(",");
          console.log(this.form.socialId);
        } else {
          this.form.socialId = [];
        }
        if (this.form.languageId) {
          this.form.languageValue =
            this.languageOptions.find(
              (item) => item.id === this.form.languageId
            )?.type || null;
        }
      });
    },
    // 语种改变-根据languageValue同步取到languageId
    languageChange() {
      this.form.languageId =
        this.languageOptions.find(
          (item) => item.type === this.form.languageValue
        )?.id || null;
    },
    //根据语言id获取时区等
    formatLanguage(languageId) {
      const language = this.languageOptions.find(
        (item) => item.id === languageId
      );
      if (language) {
        const parts = language.name.split("(");
        if (parts.length === 2) {
          const country = parts[1].replace(")", "").trim();
          const languageName = parts[0].trim();
          return `${country} ${languageName}`;
        }
        return language.name;
      }
      return "--";
    },
    socialIdChange() {
      console.log(this.form.socialId);
      if (this.form.socialId && this.form.socialId.length > 0) {
        this.form.socialIds = this.form.socialId.join(",");
      } else {
        this.form.socialIds = undefined;
      }
      this.$forceUpdate();
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.confirmExistId()) {
            this.$modal.msgError(this.$t("operation.selectExistAccount"));
            return;
          }
          if (!this.getSameTimeZoneId()) {
            this.$modal.msgError(this.$t("operation.selectTimeZoneAccount"));
            return;
          }
          if (this.form.daterange) {
            this.form.beginDate = this.form.daterange[0];
            this.form.endDate = this.form.daterange[1];
          }

          this.buttonLoading = true;
          if (this.form.id != null) {
            updateProgramme(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$emit("confirm");
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            addProgramme(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$emit("confirm");
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    confirmExistId() {
      const allExist = this.form.socialId.every((id) =>
        this.accountList.some((account) => account.id === id)
      );
      return allExist;
    },
    getSameTimeZoneId() {
      const selectedAccounts = this.accountList.filter((account) =>
        this.form.socialId.includes(String(account.id))
      );
      const timeZoneIds = selectedAccounts.map((account) => account.timeZoneId); // 提取 timeZoneId
      const areTimeZonesEqual = timeZoneIds.every(
        (timeZoneId) => timeZoneId === timeZoneIds[0]
      ); // 检查 timeZoneId 是否全部相等
      return areTimeZonesEqual;
    },
    handleConfirm() {
      this.$emit("confirm", this.form);
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 5px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
</style>
