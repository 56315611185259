var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "flex-row-center f-between" }, [
        (_vm.executeStatus == 0 ? true : false)
          ? _c(
              "div",
              { staticClass: "flex-row-center f-shrink" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "gradient-button",
                    on: { click: _vm.handleAdd },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-tianjia" }),
                    _vm._v(" " + _vm._s(_vm.$t("operation.addTopic")) + " "),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "delete-button",
                    attrs: { disabled: _vm.multiple },
                    on: { click: _vm.handleDelete },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-delete" }),
                    _vm._v(" " + _vm._s(_vm.$t("operation.delBtn")) + " "),
                  ]
                ),
              ],
              1
            )
          : _c("div"),
        _c(
          "div",
          { staticClass: "flex-row f-end" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.$t("refresh"),
                  placement: "top",
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-a-danyuanpeizhi6",
                  on: { click: _vm.getList },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "mt32",
          attrs: {
            "header-row-class-name": "custom_table_header",
            height: "calc(100vh - 220px)",
            data: _vm.programmeItemsList,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "cell-mouse-enter": _vm.cellMouseEnter,
            "cell-mouse-leave": _vm.cellMouseLeave,
          },
        },
        [
          _c("el-empty", {
            attrs: { slot: "empty", "image-size": 180 },
            slot: "empty",
          }),
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.topic"),
              align: "center",
              prop: "videoTheme",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.video"),
              align: "center",
              prop: "videoUrl",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex-center" }, [
                      scope.row.videoGenerateStatus == 0
                        ? _c(
                            "div",
                            { staticClass: "video-cover video-bg flex-center" },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont icon-shipinshengchengzhong",
                              }),
                            ]
                          )
                        : scope.row.videoGenerateStatus == 2
                        ? _c(
                            "div",
                            { staticClass: "video-cover video-bg flex-center" },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont icon-shipinshengchengshibai",
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "cellVideo" },
                            [
                              _c("video", {
                                staticClass: "video-cover",
                                attrs: { src: scope.row.videoUrl },
                              }),
                              _c(
                                "video-button",
                                {
                                  attrs: {
                                    url: scope.row.videoUrl,
                                    tip: _vm.$t("issueManager.playVideo"),
                                  },
                                },
                                [
                                  _c("template", { slot: "customButton" }, [
                                    _c("div", { staticClass: "cricle" }, [
                                      _c("i", {
                                        staticClass: "el-icon-caret-right",
                                      }),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.generationStatus"),
              align: "center",
              prop: "videoGenerateStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "videoGenerateStatus",
                        class:
                          "videoGenerateStatus-" +
                          scope.row.videoGenerateStatus,
                        style:
                          scope.row.hover && _vm.executeStatus == 0
                            ? "opacity: 0"
                            : "opacity: 1",
                      },
                      [
                        scope.row.videoGenerateStatus == 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("model.generated"))),
                            ])
                          : scope.row.videoGenerateStatus == 2
                          ? _c("div", { staticClass: "flex-column-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("issueManager.failed")) +
                                  " "
                              ),
                              scope.row.errorMsg
                                ? _c(
                                    "span",
                                    { staticClass: "generateStatus-des" },
                                    [_vm._v(_vm._s(scope.row.errorMsg))]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "flex-column-center" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("status.generating")) + "..."
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "videoGenerateStatus-des" },
                                [_vm._v(_vm._s(_vm.$t("status.generatingTip")))]
                              ),
                            ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.hover,
                            expression: "scope.row.hover",
                          },
                        ],
                        staticClass: "flex-row-center operate-hover",
                      },
                      [
                        (
                          _vm.executeStatus == 0
                            ? scope.row.publishStatus == 0
                              ? true
                              : false
                            : false
                        )
                          ? _c(
                              "div",
                              {
                                staticClass: "flex-row-center list-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "iconfont icon-edit" }),
                                _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                              ]
                            )
                          : _vm._e(),
                        (_vm.executeStatus == 0 ? true : false)
                          ? _c(
                              "div",
                              {
                                staticClass: "flex-row-center list-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-delete",
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.state"),
              align: "center",
              prop: "publishStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "publish-status",
                        class: "publish-status-" + scope.row.publishStatus,
                        style:
                          scope.row.hover && _vm.executeStatus == 0
                            ? "opacity: 0"
                            : "opacity: 1",
                      },
                      [
                        scope.row.publishStatus == 0
                          ? _c("div", { staticClass: "flex-center" }, [
                              _c("i", { staticClass: "iconfont icon-dacha3" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("issueManager.unpublished")) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        scope.row.publishStatus == 1
                          ? _c("div", { staticClass: "flex-center" }, [
                              _c("i", {
                                staticClass: "iconfont icon-a-dacha3",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("issueManager.published")) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        scope.row.publishStatus == 2
                          ? _c("div", { staticClass: "hidden flex-column" }, [
                              _c("div", { staticClass: "flex-center" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-a-dacha2",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.failed")) +
                                    " "
                                ),
                              ]),
                              scope.row.errorMsg
                                ? _c(
                                    "span",
                                    { staticClass: "publish-status-des" },
                                    [_vm._v(_vm._s(scope.row.errorMsg))]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.releaseTime"),
              align: "center",
              prop: "publishTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style:
                          scope.row.hover && _vm.executeStatus == 0
                            ? "opacity: 0"
                            : "opacity: 1",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.parseTime(scope.row.publishTime, "{y}-{m}-{d}")
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "publicDrawer",
          attrs: {
            size: 700,
            visible: _vm.open,
            "destroy-on-close": true,
            wrapperClosable: false,
            "with-header": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    _vm.open = false
                  },
                },
              }),
            ]),
          ]),
          _c("EditStrategyItem", {
            ref: "editStrategy",
            attrs: {
              programmeId: _vm.queryParams.programmeId,
              languageOptions: _vm.languageOptions,
            },
            on: {
              confirm: _vm.confirmEditStrategy,
              cancel: _vm.cancelEditStrategy,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }