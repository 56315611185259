var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("operation.planName"), prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "50",
                  placeholder: _vm.$t("operation.planName"),
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.timePeriod"),
                prop: "daterange",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "500px" },
                attrs: {
                  disabled: _vm.disabled,
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": _vm.$t("time.startDate"),
                  "end-placeholder": _vm.$t("time.endingDate"),
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.form.daterange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "daterange", $$v)
                  },
                  expression: "form.daterange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.videoTopicCount"),
                prop: "videoCount",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { color: "gray", "font-size": "12px" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("operation.videoTopicRangea")) + " "
                  ),
                ]
              ),
              _c("el-input-number", {
                staticStyle: { width: "500px" },
                attrs: { disabled: _vm.disabled, min: 1, max: 100 },
                model: {
                  value: _vm.form.videoCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "videoCount", $$v)
                  },
                  expression: "form.videoCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.releaseMethod"),
                prop: "publishType",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.form.publishType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "publishType", $$v)
                    },
                    expression: "form.publishType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "time" } }, [
                    _vm._v(_vm._s(_vm.$t("operation.distributeEvenly"))),
                  ]),
                  _c("el-radio", { attrs: { label: "ai" } }, [
                    _vm._v(_vm._s(_vm.$t("operation.aiAssign"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.userProfile"),
                prop: "userPortrait",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "textarea",
                  maxlength: "200",
                  "show-word-limit": "",
                  placeholder: _vm.$t("operation.enterUserProfile"),
                  autosize: { minRows: 6, maxRows: 8 },
                },
                model: {
                  value: _vm.form.userPortrait,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userPortrait", $$v)
                  },
                  expression: "form.userPortrait",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.planLanguage"),
                prop: "languageValue",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("operation.selectPlanLanguage"),
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.languageChange },
                  model: {
                    value: _vm.form.languageValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "languageValue", $$v)
                    },
                    expression: "form.languageValue",
                  },
                },
                _vm._l(_vm.dict.type.language, function (dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.socialAccount"),
                prop: "socialIds",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { color: "gray", "font-size": "12px" } },
                [_vm._v(_vm._s(_vm.$t("operation.accountSupport")))]
              ),
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: {
                    filterable: "",
                    multiple: "",
                    placeholder: _vm.$t("operation.selectSocialAccount"),
                  },
                  on: { change: _vm.socialIdChange },
                  model: {
                    value: _vm.form.socialId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "socialId", $$v)
                    },
                    expression: "form.socialId",
                  },
                },
                _vm._l(_vm.accountList, function (account) {
                  return _c(
                    "el-option",
                    {
                      key: account.id,
                      attrs: {
                        label: account.displayName,
                        value: String(account.id),
                      },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(account.displayName)),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.formatLanguage(account.languageId)))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "f-shrik flex-center mt32" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancelBtn",
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn",
              attrs: { loading: _vm.buttonLoading },
              on: { click: _vm.submitForm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }