// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/model_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.mt-manager[data-v-7ae38e13] {\n  margin: 16px 24px;\n}\n.mt-leftActiveTab[data-v-7ae38e13] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.mt-leftActiveTab .tabItem[data-v-7ae38e13] {\n  height: 22px;\n  font-family: PingFang-SC, PingFang-SC;\n  font-weight: bold;\n  font-size: 16px;\n  color: rgba(0, 0, 0, 0.9);\n  line-height: 22px;\n  text-align: left;\n  font-style: normal;\n  cursor: pointer;\n  margin-right: 24px;\n}\n.mt-leftActiveTab .activeTab[data-v-7ae38e13] {\n  background: -webkit-gradient(linear, left bottom, left top, from(#0089ff), to(#6e16d1));\n  background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);\n  background-clip: text;\n  /* 无前缀标准写法 */\n  -webkit-background-clip: text;\n  /* 兼容 WebKit 浏览器 */\n  -webkit-text-fill-color: transparent;\n}\n.mt-model[data-v-7ae38e13] {\n  width: calc(25% - 24px);\n  height: 180px;\n  background: #ffffff;\n  border-radius: 12px;\n  border: 2px dashed rgba(179, 179, 179, 0.38);\n  margin: 0 24px 24px 0;\n  cursor: pointer;\n}\n.mt-model .mt-add[data-v-7ae38e13] {\n  width: 60px;\n  height: 60px;\n  font-size: 28px;\n  color: #0156ff;\n  border-radius: 19px;\n  background: #e5eeff;\n}\n.mt-model .mt-add-text[data-v-7ae38e13] {\n  font-family: PingFangSC, PingFang SC;\n  font-weight: 400;\n  font-size: 14px;\n  color: #8f92a1;\n  line-height: 20px;\n  text-align: left;\n  font-style: normal;\n  margin-top: 16px;\n}\n.mt-model-item[data-v-7ae38e13] {\n  width: calc(25% - 24px);\n  height: 180px;\n  background: #ffffff;\n  border-radius: 12px;\n  border: 2px solid rgba(179, 179, 179, 0.38);\n  margin: 0 24px 24px 0;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  /* 背景图片宽高拉伸充满整个屏幕 */\n  background-position: center;\n  /* 背景图片居中 */\n  background-repeat: no-repeat;\n  /* 防止背景图片重复 */\n}", ""]);
// Exports
module.exports = exports;
