var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column h-100",
    },
    [
      _c(
        "el-descriptions",
        {
          attrs: {
            "label-class-name": "my-label",
            title: _vm.$t("model.basicInfo"),
            column: 1,
            border: "",
          },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("model.modelName"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.modelName))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("model.modelDescription"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.description))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("time.createTime"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.createTime))]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.detail.modelFiles && _vm.detail.modelFiles.length > 0
        ? _c("div", { staticClass: "model-title" }, [
            _vm._v(_vm._s(_vm.$t("model.fileModel"))),
          ])
        : _vm._e(),
      _vm.detail.modelFiles && _vm.detail.modelFiles.length > 0
        ? _c(
            "el-table",
            {
              attrs: {
                "header-row-class-name": "custom_table_header",
                height: "200",
                data: _vm.detail.modelFiles,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "fileName",
                  label: _vm.$t("model.fileName"),
                  align: "left",
                  "min-width": "150",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "single-line-ellipsis" }, [
                            _vm._v(_vm._s(scope.row.fileName)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3367699255
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileSize",
                  label: _vm.$t("model.fileSize"),
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "single-line-ellipsis" }, [
                            _vm._v(_vm._s(scope.row.fileSize)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  734387637
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "modelType",
                  label: _vm.$t("model.trainingModule"),
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "single-line-ellipsis" }, [
                            _vm._v(_vm._s(scope.row.modelType)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1264304769
                ),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: _vm.$t("model.trainingResult"),
                    align: "center",
                    "min-width": "100",
                  },
                },
                [
                  [
                    _c("span", { staticClass: "success-blue" }, [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _vm._v(_vm._s(_vm.$t("model.trainingSuccess"))),
                    ]),
                  ],
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: _vm.$t("model.trainingTime"),
                  align: "center",
                  prop: "createTime",
                  width: "180",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(scope.row.createTime) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1943637591
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.detail.modelDocumentSegments &&
      _vm.detail.modelDocumentSegments.length > 0
        ? _c("div", { staticClass: "model-title" }, [
            _vm._v(_vm._s(_vm.$t("model.qnaModel"))),
          ])
        : _vm._e(),
      _vm.detail.modelDocumentSegments &&
      _vm.detail.modelDocumentSegments.length > 0
        ? _c(
            "el-table",
            {
              attrs: {
                "header-row-class-name": "custom_table_header",
                height: "200",
                data: _vm.detail.modelDocumentSegments,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "problem",
                  label: _vm.$t("model.question"),
                  align: "left",
                  "min-width": "150",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "single-line-ellipsis" }, [
                            _vm._v(_vm._s(scope.row.problem)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1335991645
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "modelType",
                  label: _vm.$t("model.trainingModule"),
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "single-line-ellipsis" }, [
                            _vm._v(_vm._s(scope.row.modelType)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1264304769
                ),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: _vm.$t("model.trainingResult"),
                    align: "center",
                    "min-width": "100",
                  },
                },
                [
                  [
                    _c("span", { staticClass: "success-blue" }, [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _vm._v(_vm._s(_vm.$t("model.trainingSuccess"))),
                    ]),
                  ],
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: _vm.$t("model.trainingTime"),
                  align: "center",
                  prop: "createTime",
                  width: "180",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(scope.row.createTime) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1943637591
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }