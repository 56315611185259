<template>
  <div
    class="flex-column h-100"
    v-loading="loading"
  >
    <el-descriptions
      label-class-name="my-label"
      :title="$t('model.basicInfo')"
      :column="1"
      border
    >
      <el-descriptions-item>
        <template slot="label">{{ $t('model.modelName') }}</template>
        <span>{{ detail.modelName }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('model.modelDescription') }}</template>
        <span>{{ detail.description }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('time.createTime') }}</template>
        <span>{{ detail.createTime }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <div
      class="model-title"
      v-if="detail.modelFiles && detail.modelFiles.length > 0"
    >{{ $t('model.fileModel') }}</div>
    <el-table
      v-if="detail.modelFiles && detail.modelFiles.length > 0"
      header-row-class-name="custom_table_header"
      height="200"
      :data="detail.modelFiles"
    >
      <el-table-column
        prop="fileName"
        :label="$t('model.fileName')"
        align="left"
        min-width="150"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">{{ scope.row.fileName }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fileSize"
        :label="$t('model.fileSize')"
        align="center"
        min-width="100"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">{{ scope.row.fileSize }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="modelType"
        :label="$t('model.trainingModule')"
        align="center"
        min-width="100"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">{{ scope.row.modelType }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('model.trainingResult')"
        align="center"
        min-width="100"
      >
        <template>
          <span class="success-blue"><i class="el-icon-circle-check"></i>{{ $t('model.trainingSuccess') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        :label="$t('model.trainingTime')"
        align="center"
        prop="createTime"
        width="180"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.createTime }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="model-title"
      v-if="detail.modelDocumentSegments && detail.modelDocumentSegments.length > 0"
    >{{ $t('model.qnaModel') }}</div>
    <el-table
      v-if="detail.modelDocumentSegments && detail.modelDocumentSegments.length > 0"
      header-row-class-name="custom_table_header"
      height="200"
      :data="detail.modelDocumentSegments"
    >
      <el-table-column
        prop="problem"
        :label="$t('model.question')"
        align="left"
        min-width="150"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">{{ scope.row.problem }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="modelType"
        :label="$t('model.trainingModule')"
        align="center"
        min-width="100"
      >
        <template slot-scope="scope">
          <div class="single-line-ellipsis">{{ scope.row.modelType }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('model.trainingResult')"
        align="center"
        min-width="100"
      >
        <template>
          <span class="success-blue"><i class="el-icon-circle-check"></i>{{ $t('model.trainingSuccess') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        :label="$t('model.trainingTime')"
        align="center"
        prop="createTime"
        width="180"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.createTime }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { getModel } from "../../api/model/model";

export default {
  name: "Detail",
  data() {
    return {
      id: "",
      loading: false,
      detail: {},
    };
  },
  methods: {
    init(id) {
      this.id = id;
      this.detail = {};
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      getModel(this.id)
        .then((res) => {
          this.detail = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.my-label {
  width: 100px;
}
.model-title {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}
.success-blue {
  color: #0156ff;
}
.el-icon-circle-check {
  font-size: 16px;
  margin-right: 6px;
}
</style>
