var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex-column model-item",
      on: { mouseover: _vm.mouseoverItem, mouseleave: _vm.mouseleaveItem },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-row-center",
          staticStyle: { "margin-top": "24px" },
        },
        [
          _vm._m(0),
          _c("span", { staticClass: "model-name ellipsis-option" }, [
            _vm._v(_vm._s(_vm.item.modelName)),
          ]),
          _vm.item.status == 1
            ? _c(
                "div",
                {
                  staticClass: "flex-row-center model-status",
                  staticStyle: { color: "#0156FF" },
                },
                [
                  _c("i", { staticClass: "iconfont icon-a-dacha3" }),
                  _vm._v(" " + _vm._s(_vm.$t("model.generated")) + " "),
                ]
              )
            : _vm.item.status == 0
            ? _c(
                "div",
                {
                  staticClass: "flex-row-center model-status",
                  staticStyle: { color: "#00B264" },
                },
                [
                  _c("i", { staticClass: "iconfont icon-gongzuotai2" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("model.trainingInProgress")) + " "
                  ),
                ]
              )
            : _vm._e(),
          _c("el-tooltip", { attrs: { content: _vm.item.errorMsg } }, [
            _vm.item.status == 2
              ? _c(
                  "div",
                  {
                    staticClass: "flex-row-center model-status",
                    staticStyle: { color: "#E34D59" },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-a-dacha2" }),
                    _vm._v(" " + _vm._s(_vm.$t("model.trainingFailed")) + " "),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("span", { staticClass: "line-2 model-description" }, [
        _vm._v(_vm._s(_vm.item.description)),
      ]),
      !_vm.item.hover
        ? _c("div", { staticClass: "model-bottom flex-row-center f-end" }, [
            _c("div", [_vm._v(_vm._s(_vm.item.createTime))]),
          ])
        : _vm._e(),
      _vm.item.hover && _vm.item.status != 0
        ? _c(
            "div",
            { staticClass: "model-buttons flex-center" },
            [
              _c("el-tooltip", { attrs: { content: _vm.$t("view") } }, [
                _c("i", {
                  staticClass: "iconfont icon-a-xialajiantoubeifen3 color-blue",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("view")
                    },
                  },
                }),
              ]),
              _c("el-tooltip", { attrs: { content: _vm.$t("edit") } }, [
                _c("i", {
                  staticClass: "iconfont icon-edit color-blue",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("edit")
                    },
                  },
                }),
              ]),
              _c("el-tooltip", { attrs: { content: _vm.$t("remove") } }, [
                _c("i", {
                  staticClass: "iconfont icon-delete color-blue",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.delModel($event)
                    },
                  },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.item.hover && _vm.item.status == 0
        ? _c("div", { staticClass: "model-buttons flex-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("model.trainingLocked")) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "model-image flex-center f-shrink" }, [
      _c("i", {
        staticClass: "iconfont icon-moxingxunlianbeifen",
        staticStyle: { "font-size": "26px", color: "white" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }